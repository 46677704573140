<template>
  <div class="goods">
    <van-nav-bar title="详情" left-text="返回" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="toHome"/>
      </template>
    </van-nav-bar>
    <van-swipe class="goods-swipe" :autoplay="3000">
      <van-swipe-item v-for="thumb in productInfo.slider_image" :key="thumb">
        <img :src="thumb">
      </van-swipe-item>
    </van-swipe>
    <van-cell-group>
      <van-cell>
        <div class="goods-title">{{ productInfo.store_name }}</div>
      </van-cell>
      <van-cell class="goods-express">
        <van-col span="14">库存：{{ productInfo.stock }}</van-col>
      </van-cell>
    </van-cell-group>
    <van-divider content-position="left"
                 :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
    >
      销量
    </van-divider>
    <van-row>
      <van-col span="12">
        <van-dropdown-menu>
          <van-dropdown-item v-model="dateOptionValue" :options="dateOptions" @change="onChangeDateOption"/>
        </van-dropdown-menu>
      </van-col>
      <van-col span="12">
        <van-cell title="选择日期" style="height: 48px;" :value="date" @click="showCalendar = true"/>
      </van-col>
    </van-row>

    <van-grid :column-num="4">
      <van-grid-item v-for="item in statisticSlReInfo" :key="item.sku">
        <el-statistic :title="item.sku">
          <template slot="formatter">
            <span style="color: #409EFF;">{{ item.saleNum }}</span><span
            v-if="item.returnNum !== null && item.returnNum > 0">/</span><span style="color: #F56C6C;"
                                                                               v-if="item.returnNum !== null && item.returnNum > 0">{{
              item.returnNum
            }}</span>
          </template>
        </el-statistic>
      </van-grid-item>
    </van-grid>

    <van-calendar v-model="showCalendar" type="range" :show-confirm="false" :min-date="minDate" :max-date="maxDate"
                  @confirm="onConfirm"/>
  </div>
</template>

<script>
import {
  Calendar,
  Divider,
  DropdownItem,
  DropdownMenu,
  Grid,
  GridItem,
  Image,
  NavBar,
  Popup,
  Swipe,
  SwipeItem,
  Tag,
} from 'vant';
import * as productService from "@/api/admin/product";
import * as analysisService from "@/api/admin/analysis";
import dayjs from "dayjs";

export default {
  components: {
    [Tag.name]: Tag,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Popup.name]: Popup,
    [Image.name]: Image,
    [Divider.name]: Divider,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Calendar.name]: Calendar,
    [NavBar.name]: NavBar,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
  },

  data() {
    return {
      productInfo: {},
      statisticSlReInfo: [],
      date: '',
      show: false,
      showCalendar: false,
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2030, 0, 31),
      value1: 0,
      dateOptionValue: '0',
      dateOptions: [
        {text: '自选时间', value: '-1'},
        {text: '去年', value: '0'},
        {text: '今年', value: '1'},
        {text: '前年', value: '2'},
      ],
    };
  },

  created() {
    if (this.$route.params && this.$route.params.productId) {
      productService.getProductInfo(this.$route.params.productId).then(res => {
        console.log(res)
        this.productInfo = res.productInfo
      }).catch(error => {
      });
      const param = {
        productId: this.$route.params.productId,
        dateType: this.dateOptionValue,
      }
      this.getStatisticSaleReturn(param)
    }
  },
  methods: {
    getStatisticSaleReturn(param) {
      analysisService.getStatisticSaleReturn(param).then(res => {
        if (res.status === 200) {
          console.log(res)
          this.statisticSlReInfo = res.data
        }
      }).catch(error => {
      })
    },
    toHome() {
      this.$router.push({path: '/index'})
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onConfirm(date) {
      this.dateOptionValue = '-1'
      const [start, end] = date
      this.showCalendar = false
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`
      const param = {
        productId: this.$route.params.productId,
        startDate: dayjs(start).format('YYYY-MM-DD HH:mm:ss'),
        endDate: dayjs(end).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      }
      this.getStatisticSaleReturn(param)
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`
    },
    formatDate2(date) {
      console.log('date', date)
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} 00:00:00`
    },
    onChangeDateOption() {
      this.date = ''
      const param = {
        productId: this.$route.params.productId,
        dateType: this.dateOptionValue,
      }
      this.getStatisticSaleReturn(param)
    }
  }
};
</script>

<style lang="less">
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}

.goods {
  padding-bottom: 50px;

  &-swipe {
    img {
      width: 100%;
      display: block;
    }
  }

  &-title {
    font-size: 16px;
  }

  &-price {
    color: #f44;
  }

  &-express {
    color: #999;
    font-size: 12px;
    padding: 5px 15px;
  }

  &-cell-group {
    margin: 15px 0;

    .van-cell__value {
      color: #999;
    }
  }

  &-tag {
    margin-left: 5px;
  }
}
</style>
