<template>
  <div>
    <van-nav-bar title="库存操作日志" left-text="返回" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="toHome"/>
      </template>
    </van-nav-bar>
    <div>
      <van-search
        v-model="searchParam.storeName"
        show-action
        label="商品名"
        placeholder="请输入商品名关键词"
        @search="onSearch"
        @clear="onSearchClear"
        @cancel="onSearchClear"
      >
      </van-search>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="van-address-list" style="padding-bottom: 0">
          <div v-for="item in dataList" :key="item.id" :name="item.id">
            <div class="van-address-item" style="margin-bottom: 5px;">
              <div class="van-cell van-cell--borderless">
                <div class="van-cell__value van-cell__value--alone van-address-item__value">
                  <div class="van-address-item__name">
                    <span style="margin-right: 3px;">{{ item.storeName }}</span>
                    <van-tag type="primary" v-if="item.type === 'SUBTRACT'">减</van-tag>
                    <van-tag type="danger" v-if="item.type === 'ADD'">加</van-tag>
                    <van-tag type="success" v-if="item.type === 'COVER'">覆盖</van-tag>
                    <el-link type="info" style="margin-left: 2px;">{{ item.sceneDesc }}</el-link>
                    <el-link type="danger" v-if="item.mistake" style="margin-left: 2px;">错误</el-link>
                  </div>
                  <div class="van-address-item__address">
                    {{ item.createTime }}
                  </div>
                  <div class="van-address-item__address">
                    <el-link style="margin-right: 6px;">{{ item.sku }}</el-link>
                    <el-link type="primary">前: {{ item.operateBeforeStock }}</el-link>
                    <el-divider direction="vertical"></el-divider>
                    <el-link type="danger">
                      <span v-if="item.type === 'SUBTRACT'">减:</span>
                      <span v-if="item.type === 'ADD'">加:</span>
                      <span v-if="item.type === 'COVER'">覆盖:</span>
                      {{ item.operateNum }}</el-link>
                    <el-divider direction="vertical"></el-divider>
                    <el-link type="success">后:{{ item.operateAfterStock }}</el-link>
                  </div>
                </div>
                <van-icon class="van-address-item__edit" name="more-o" />
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import {AddressList, Card, List, Loading, NavBar, Notify, PullRefresh, Search, Tag} from 'vant';

import * as adminStockService from "@/api/admin/stock";

export default {
  components: {
    [Tag.name]: Tag,
    [Card.name]: Card,
    [Search.name]: Search,
    [Loading.name]: Loading,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Notify.name]: Notify,
    [AddressList.name]: AddressList,
    [NavBar.name]: NavBar
  },
  name: "StockOperateLog",
  data() {
    return {
      searchParam: {
        storeName: '',
      },
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      pages: {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      },
      searchParamValue: '',
      searchPopupShow: false,
    };
  },
  created() {
  },
  methods: {
    onLoad() {
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
      this.searchParam.page = this.pages.currPage
      this.searchParam.limit = 10
      let that = this;
      adminStockService.getStockOperateLog(this.searchParam).then(res => {
        if (res.status === 200) {
          that.dataList = that.dataList.concat(res.data.records)
          this.loading = false;
          that.pages.currPage++;
          that.pages.pageCount = res.data.pages;
          if (that.pages.currPage > that.pages.pageCount) {
            that.finished = true;
          }
        }
      })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.dataList = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pages.currPage = 1;
      this.onLoad();
    },
    resetData() {
      this.pages = {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      };
      this.loading = false;
      this.finished = false;
      this.refreshing = false;
    },
    onSearch() {
      this.onRefresh()
    },
    onSearchClear() {
      this.searchParam.storeName = null
      this.onRefresh();
    },
    onMerge() {
      this.$router.push({path: '/order/debt/merge/' + this.debt.userId + '/' + this.debt.id})
    },
    toHome() {
      this.$router.push({path: '/index'})
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    initData() {
      adminStockService.getStockOperateLog().then(res => {
        console.log(res)
        this.debt = res
        this.debtChildren = res.children
        this.timelines = res.timelines
        this.timelineActive = this.timelines - 1
      });
    },
    toInfo(item) {
      console.log(item)
      if (item.type == 0) {
        this.$router.push({path: '/order/info/' + item.orderId})
      } else if (item.type == 1 && this.$route.params.debtId != item.id) {
        this.$router.push({path: '/order/debt/info/' + item.id})
      }
    },
  },
};
</script>

<style lang="less">
</style>
