<template>
  <div>
    <van-nav-bar title="标题" left-text="返回" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="toHome"/>
      </template>
    </van-nav-bar>
    <div>
      <van-search
        v-model="searchParam.storeName"
        show-action
        label="商品名"
        placeholder="请输入商品名关键词"
        @search="onSearch"
        @clear="onSearchClear"
      >
        <!--        <template #action>-->
        <!--          <div @click="showPopup" style="color: #1989fa">筛选</div>-->
        <!--        </template>-->
      </van-search>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="van-address-list" style="padding-bottom: 0">
          <div v-for="item in dataList" :key="item.id" :name="item.id">
            <div class="van-address-item" style="margin-bottom: 5px;">
              <div class="van-cell van-cell--borderless">
                <div class="van-cell__value van-cell__value--alone">
                  <div class="van-address-item__name">
                    <span style="margin-right: 3px;">{{ item.inspectionTime }}</span>
                  </div>
                  <div class="van-address-item__address">
                    <van-row>
                      <el-statistic title="全部">
                        <template slot="formatter">
                          <span style="color: #409EFF;">{{item.inspectionNum}}</span>/<span style="color: #F56C6C;" @click="showErrorExtra(item)">{{item.errorNum}}</span>
                        </template>
                      </el-statistic>
                    </van-row>
                    <van-row>
                      <van-col span="6" v-for="subItem in item.dataList" :key="subItem.id" :name="subItem.id">
                        <el-statistic :title="subItem.sceneDesc">
                          <template slot="formatter">
                            <span style="color: #409EFF;" @click="showExtra(subItem)">{{subItem.inspectionNum}}</span>/<span style="color: #F56C6C;" @click="showErrorExtra(subItem)">{{subItem.errorNum}}</span>
                          </template>
                        </el-statistic>
                      </van-col>
                    </van-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>

    <van-popup v-model="popupShow" position="bottom" :style="{ height: '70%' }">
      <div class="van-address-list" style="padding-bottom: 0">
        <div v-for="item in extraList" :key="item.id" :name="item.id">
          <van-divider content-position="left" :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
            {{ item.sceneDesc }} {{ item.related }}</van-divider>
          <div v-for="subItem in item.allInspectionList" :key="subItem.id" :subItem="item.id">
          <div class="van-address-item" style="margin-bottom: 5px;">
            <div class="van-cell van-cell--borderless">
              <div class="van-cell__value van-cell__value--alone">
                <div class="van-address-item__name">
                  <span style="margin-right: 3px;">{{ subItem.storeName }}</span>
                  <van-tag type="primary" v-if="subItem.type === 'SUBTRACT'">减</van-tag>
                  <van-tag type="danger" v-if="subItem.type === 'ADD'">加</van-tag>
                  <van-tag type="success" v-if="subItem.type === 'COVER'">覆盖</van-tag>
                  <el-link type="info" style="margin-left: 2px;">{{ subItem.sceneDesc }}</el-link>
                </div>
                <div class="van-address-item__address">
                  {{ subItem.inspectionTime }}
                </div>
                <div class="van-address-item__address">
                  <el-link style="margin-right: 6px;">{{ subItem.sku }}</el-link>
                  <el-divider direction="vertical"></el-divider>
                  <el-link type="primary">
                    <span v-if="subItem.type === 'SUBTRACT'">减:</span>
                    <span v-if="subItem.type === 'ADD'">加:</span>
                    <span v-if="subItem .type === 'COVER'">覆盖:</span>
                    {{ subItem.number }}</el-link>
                  <el-divider v-if="subItem.isDel === 1" direction="vertical"></el-divider>
                  <el-link type="danger">
                    <span v-if="subItem.isDel === 1">删之前: {{subItem.extra}}</span>
                  </el-link>
                  <van-tag v-if="!subItem.validity" type="danger" style="margin-left: 5px;">错误</van-tag>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import {AddressList, Card, List, Loading, NavBar, Notify, PullRefresh, Search, Tag} from 'vant';

import * as adminStockService from "@/api/admin/stock";

export default {
  components: {
    [Tag.name]: Tag,
    [Card.name]: Card,
    [Search.name]: Search,
    [Loading.name]: Loading,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Notify.name]: Notify,
    [AddressList.name]: AddressList,
    [NavBar.name]: NavBar
  },
  name: "StockOperateLog",
  data() {
    return {
      popupShow: false,
      searchParam: {
        storeName: '',
        onlySeeUnfinished: true
      },
      dataList: [],
      extraList: [],
      loading: false,
      finished: false,
      refreshing: false,
      pages: {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      },
      searchParamValue: '',
      searchPopupShow: false,
    };
  },
  created() {
  },
  methods: {
    onLoad() {
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
      this.searchParam.page = this.pages.currPage
      this.searchParam.limit = 10
      let that = this;
      adminStockService.getStockOperateInspection(this.searchParam).then(res => {
        if (res.status === 200) {
          that.dataList = that.dataList.concat(res.data.records)
          this.loading = false;
          that.pages.currPage++;
          that.pages.pageCount = res.data.pages;
          if (that.pages.currPage > that.pages.pageCount) {
            that.finished = true;
          }
        }
      })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.dataList = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pages.currPage = 1;
      this.onLoad();
    },
    resetData() {
      this.pages = {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      };
      this.loading = false;
      this.finished = false;
      this.refreshing = false;
    },
    onSearch() {
      // this.searchParam.factoryName = this.searchParamValue
      this.searchParam.onlySeeUnfinished = false
      this.onRefresh()
    },
    onSearchClear() {
      this.searchParam.factoryUuid = null
      this.searchParam.factoryName = null
      this.searchParam.address = null
      this.searchParam.orderDatetime = null
      this.searchParam.onlySeeUnfinished = true
      this.onRefresh();
    },
    onMerge() {
      this.$router.push({path: '/order/debt/merge/' + this.debt.userId + '/' + this.debt.id})
    },
    toHome() {
      this.$router.push({path: '/index'})
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    initData() {
      adminStockService.getStockOperateLog().then(res => {
        console.log(res)
        this.debt = res
        this.debtChildren = res.children
        this.timelines = res.timelines
        this.timelineActive = this.timelines - 1
      });
    },
    toInfo(item) {
      console.log(item)
      if (item.type == 0) {
        this.$router.push({path: '/order/info/' + item.orderId})
      } else if (item.type == 1 && this.$route.params.debtId != item.id) {
        this.$router.push({path: '/order/debt/info/' + item.id})
      }
    },
    showExtra(item) {
      if (item.inspectionNum < 1) {
        return
      }
      console.log(item)
      this.extraList = item.extraList
      this.popupShow = true
    },
    showErrorExtra(item) {
      if (item.errorNum < 1) {
        return
      }
      console.log(item.extraList)
      this.extraList = item.extraList

      this.popupShow = true
    }
  },
};
</script>

<style lang="less">
</style>
