import request from '@/utils/admin/request'

export function getProductLackStockNum(param) {
    return request({
        url: '/wholesale-product-stock/get-product-lack-stock-num',
        method: 'get',
        params: param
    });
}

export function getStockOperateLog(param) {
    return request({
        url: '/charge-account/stock/page-operate-log',
        method: 'get',
        params: param
    });
}

export function getStockOperateInspection(param) {
    return request({
        url: '/charge-account/stock/page-operate-inspection',
        method: 'get',
        params: param
    });
}

export function getStockOperateCollect(param) {
    return request({
        url: '/charge-account/stock/page-operate-collect',
        method: 'get',
        params: param
    });
}
