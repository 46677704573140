<template>
  <div>
    <van-nav-bar title="库存聚合" left-text="返回" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="toHome"/>
      </template>
    </van-nav-bar>
    <div>
      <van-search
        v-model="searchParamValue"
        show-action
        label="商品名"
        placeholder="请输入商品名关键词"
        @search="onSearch"
        @clear="onSearchClear"
      >
        <template #action>
          <div @click="showPopup" style="color: #1989fa">筛选</div>
        </template>
      </van-search>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="van-address-list" style="padding-bottom: 0">
          <div v-for="item in dataList" :key="item.id" :name="item.id">
            <div class="van-address-item" style="margin-bottom: 5px;">
              <div class="van-cell van-cell--borderless">
                <div class="van-cell__value van-cell__value--alone van-address-item__value">
                  <div class="van-address-item__name">
                    <span style="margin-right: 3px;">{{ item.storeName }}</span>
                    <van-tag type="primary" v-if="item.type === 'SUBTRACT'">减</van-tag>
                    <van-tag type="danger" v-if="item.type === 'ADD'">加</van-tag>
                    <van-tag type="success" v-if="item.type === 'COVER'">覆盖</van-tag>
                  </div>
                  <div class="van-address-item__address">
                    {{ item.collectTime }}
                  </div>
                  <div class="van-address-item__address">
                    <el-link style="margin-right: 6px;">{{ item.sku }}</el-link>
                    <el-link type="danger">
                      <span v-if="item.type === 'SUBTRACT'">减:</span>
                      <span v-if="item.type === 'ADD'">加:</span>
                      <span v-if="item.type === 'COVER'">覆盖:</span>
                      {{ item.number }}
                    </el-link>
                    <el-divider direction="vertical"></el-divider>
                    <el-link type="success">库存:{{ item.stock }}</el-link>
                  </div>
                </div>
                <van-icon v-if="item.extra !== null" class="van-address-item__edit" name="more-o"
                          @click="showExtra(item.extraData)"/>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>

    <van-popup v-model="popupShow" position="bottom" :style="{ height: '70%' }">
      <div class="van-address-list" style="padding-bottom: 0">
        <div class="van-address-item" style="margin-bottom: 5px;">
          <div class="van-cell van-cell--borderless">
            <div class="van-cell__value van-cell__value--alone">
              <div class="van-address-item__name">
                <span style="margin-right: 3px;">{{ extraData.storeName }}</span>
                <van-tag type="primary" v-if="extraData.type === 'SUBTRACT'">减</van-tag>
                <van-tag type="danger" v-if="extraData.type === 'ADD'">加</van-tag>
                <van-tag type="success" v-if="extraData.type === 'COVER'">覆盖</van-tag>
              </div>
              <div class="van-address-item__address">
                {{ extraData.collectTime }}
              </div>
              <div class="van-address-item__address">
                <el-link style="margin-right: 6px;">{{ extraData.sku }}</el-link>
                <el-link type="danger">
                  <span v-if="extraData.type === 'SUBTRACT'">减:</span>
                  <span v-if="extraData.type === 'ADD'">加:</span>
                  <span v-if="extraData.type === 'COVER'">覆盖:</span>
                  {{ extraData.number }}
                </el-link>
                <el-divider direction="vertical"></el-divider>
                <el-link type="success">库存:{{ extraData.stock }}</el-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup position="bottom" :style="{ height: '90%' }" v-model="searchPopupShow">
      <van-field v-model="searchParam.storeName" label="商品名" placeholder="请输入商品名"/>
      <van-field v-model="searchParam.sku" label="规格" placeholder="请输入规格"/>
      <van-field v-model="date" label="订单日期" placeholder="选择订单日期" @click="showCalendar = true"/>
      <van-calendar v-model="showCalendar" type="range" :show-confirm="false" :min-date="minDate" :max-date="maxDate"
                    @confirm="onConfirm"/>
      <div style="text-align: center;">
        <van-button style="margin-top: 20px; width: 80%" size="large" type="primary" @click="search">搜索</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {AddressList, Card, List, Loading, NavBar, Notify, PullRefresh, Search, Tag} from 'vant';

import * as adminStockService from "@/api/admin/stock";
import dayjs from "dayjs";

export default {
  components: {
    [Tag.name]: Tag,
    [Card.name]: Card,
    [Search.name]: Search,
    [Loading.name]: Loading,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Notify.name]: Notify,
    [AddressList.name]: AddressList,
    [NavBar.name]: NavBar
  },
  name: "StockOperateLog",
  data() {
    return {
      popupShow: false,
      searchParam: {
        storeName: '',
        onlySeeUnfinished: true
      },
      dataList: [],
      extraData: {},
      loading: false,
      finished: false,
      refreshing: false,
      pages: {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      },
      searchParamValue: '',
      searchPopupShow: false,
      date: '',
      showCalendar: false,
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2030, 0, 31),
    };
  },
  created() {
  },
  methods: {
    onLoad() {
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
      this.searchParam.page = this.pages.currPage
      this.searchParam.limit = 10
      let that = this;
      adminStockService.getStockOperateCollect(this.searchParam).then(res => {
        if (res.status === 200) {
          that.dataList = that.dataList.concat(res.data.records)
          this.loading = false;
          that.pages.currPage++;
          that.pages.pageCount = res.data.pages;
          if (that.pages.currPage > that.pages.pageCount) {
            that.finished = true;
          }
        }
      })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.dataList = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pages.currPage = 1;
      this.onLoad();
    },
    resetData() {
      this.pages = {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      };
      this.loading = false;
      this.finished = false;
      this.refreshing = false;
    },
    onSearch() {
      this.searchParam.storeName = this.searchParamValue
      this.onRefresh()
    },
    onSearchClear() {
      this.date = ''
      this.searchParamValue = ''
      this.searchParam.storeName = null
      this.searchParam.sku = null
      this.searchParam.startDate = null
      this.searchParam.endDate = null
      this.onRefresh();
    },
    toHome() {
      this.$router.push({path: '/index'})
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    showExtra(extraData) {
      this.extraData = extraData
      this.popupShow = true
    },
    showErrorExtra(item) {
      if (item.errorNum < 1) {
        return
      }
      console.log(item.extraList)
      this.extraList = item.extraList

      this.popupShow = true
    },
    showPopup() {
      this.searchPopupShow = true
    },
    search() {
      this.searchParamValue = '多条件筛选'
      this.onRefresh();
      this.searchPopupShow = false
    },
    onConfirm(date) {
      const [start, end] = date
      this.showCalendar = false
      console.log(start)
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`
      this.searchParam.startDate = dayjs(start).format('YYYY-MM-DD HH:mm:ss')
      this.searchParam.endDate = dayjs(end).endOf('day').format('YYYY-MM-DD HH:mm:ss')
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`
    },
  },
};
</script>

<style lang="less">
</style>
